.normalButton{
    padding: 12px 20px 12px 20px;
    font-weight: 800;
    border: 1px solid white;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: .25rem ;
    transition: .1s;

    
}

.normalButton:hover{
    background-color: white;
    color: #222
}

.normalButton:active{
    box-shadow: 0px 0px 5px 1px #ffffff70;
    
}




.shakeButton {
	animation: shake-animation 4.72s 1s ease infinite;
	transform-origin: 50% 50%;
    
  }

  @keyframes shake-animation {
	 0% { transform:translate(0,0); box-shadow: 0 0 20px 10px rgba(255,255,255,1); }
	1.78571% { transform:translate(5px,0)}
	3.57143% { transform:translate(0,0) }
	5.35714% { transform:translate(5px,0) }
	7.14286% { transform:translate(0,0) }
	8.92857% { transform:translate(5px,0) }
	10.71429% { transform:translate(0,0) }
	100% { transform:translate(0,0) }
  }