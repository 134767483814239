.app {
  min-height: 100vh;
}

.titleDiv {
  color: white;
  text-align: center;
  padding: 50px 0 50px 0;
}

.listContainer {
  height: 100%;
  display: grid;
  gap: 10px;
  width: 100vw;
  justify-content: center;
  animation: fadein 1s;
  align-content: center;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content {
  min-height: calc(100vh - 285px);
  /* min-height: 100vh; */
  display: grid;
  position: relative;
  align-items: center;
}
