.footer {
  height: 130px;
  background-color: #0a0a0a;
  margin-top: 100px;
  text-align: center;
  color: white;
  padding-top: 50px;
  border-top: 5px solid #b02a38;
  /* position: absolute; */
  /* bottom: 0; */
  /* width: 100%; */
}

.footer > a > * {
  color: white;
  padding-bottom: 15px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.footer > a:hover > * {
  color: #b02a38;
}

.footer > a:active > * {
  color: #a1c4d6;
}
