a {
  text-decoration: none;
}

body {
  font-family: "Trebuchet MS";
  margin: 0;
  padding: 0;
  min-height: 100vh;
  color: #fff;
  background-color: #0a0a0a;
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  /* background-size: 400% 400%; */
  /* -webkit-animation: Gradient 15s ease infinite; */
  /* -moz-animation: Gradient 15s ease infinite; */
  /* animation: Gradient 15s ease infinite; */
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
